import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'mc-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    constructor() { }

    ngOnInit() {}

}
