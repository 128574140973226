import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileUploadComponent } from './components/file-upload/file-upload.component'

import { DragDropDirective } from './directives/DragDropDirective'

@NgModule({
  declarations: [
    FileUploadComponent,
    DragDropDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FileUploadComponent,
    DragDropDirective
  ]
})
export class SharedModule { }
