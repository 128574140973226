import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HbCommonModule, HbUIModule } from '@handlebar/angular';
import { ReactiveFormsModule } from '@angular/forms';

import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { CoreRoutingModule } from './core-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CitySelectorComponent } from '../landingpage/components/city-selector/city-selector.component';
import { DealEditComponent } from '../landingpage/components/deal-edit/deal-edit.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { SidenavComponent } from './pages/layout/sidenav/sidenav.component';
import { DealRemoveComponent } from '../landingpage/components/deal-remove/deal-remove.component';

@NgModule({
    declarations: [
        DashboardComponent,
        CitySelectorComponent,
        DealEditComponent,
        DealRemoveComponent,
        LayoutComponent,
        SidenavComponent,
    ],
    imports: [
        BrowserModule,
        CoreRoutingModule,
        SharedModule,
        HttpClientModule,
        HbUIModule,
        HbCommonModule,
        ReactiveFormsModule,
    ],
    entryComponents: [
        CitySelectorComponent,
        DealEditComponent,
        DealRemoveComponent,
    ],
    bootstrap: [LayoutComponent]
})
export class CoreModule { }
