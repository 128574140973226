import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@handlebar/angular';

import { City } from 'src/modules/shared/models/city';
import { BusinessunitService } from 'src/modules/core/http/businessunit.service';
import { LandingpageStateService } from 'src/modules/core/services/landingpage-state.service';
import { switchMap, take } from 'rxjs/operators';
import { Landingpage } from 'src/modules/shared/models/landingpage';
import { CountryService } from 'src/modules/core/http/country.service';

@Component({
    selector: 'mc-city-selector',
    templateUrl: './city-selector.component.html',
    styleUrls: ['./city-selector.component.scss']
})
export class CitySelectorComponent implements OnInit {
    cities: SelectableCity[];
    private landingpage: Landingpage;

    constructor(
        private dialogRef: DialogRef,
        private businessunitService: BusinessunitService,
        private landingpageStateService: LandingpageStateService,
        private countryService: CountryService,
    ) { }

    ngOnInit() {
        this.landingpageStateService.landingpage$.pipe(
            take(1),
            switchMap(landingpage => {
                this.landingpage = landingpage;
                return this.countryService.getCountryByCode(landingpage.country);
            }),
            switchMap(country => {
                return this.businessunitService.getBusinessUnits(country);
            })
        ).subscribe(allCities => {
            const selected = this.landingpage.cities.map(c => c.code);
            this.cities = [];
            allCities.forEach(city => {
                this.cities.push(new SelectableCity(city, selected.indexOf(city.code) > -1));
            });
        });
    }

    toggleSelect() {
        if (this.isAllSelected()) {
            this.cities.map(city => city.selected = false);
        } else {
            this.cities.map(city => city.selected = true);
        }
    }

    isAllSelected() {
        return this.cities.filter(city => !city.selected).length == 0;
    }

    submit() {
        this.dialogRef.close(this.cities.filter(city => city.selected).map(city => new City(city)));
    }
}

export class SelectableCity extends City {
    selected = false;

    constructor(data: Partial<City>, selected: boolean) {
        super(data);
        Object.assign(this, data);
        this.selected = selected;
    }
}
