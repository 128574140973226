import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Observer } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { City } from 'src/modules/shared/models/city';
import { Country } from 'src/modules/shared/models/country';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BusinessunitService {
    private cities$: Observable<City[]>[] = [];

    constructor(private http: HttpClient) { }

    getBusinessUnits(country: Country): Observable<City[]> {
        const url = environment.baseApiUrl + 'v1/country/' + country.Id + '/businessunits';

        if (!this.cities$[country.Code]) {
            this.cities$[country.Code] = this.http.get<{ items: City[]}>(url).pipe(
                map((cities) => cities.items),
                map((cities) => cities.map((city) => new City(city))),
                shareReplay(1),
            );
        }

        return this.cities$[country.Code];
    }
}
