import { Guid } from 'guid-typescript';

export class Country {
    Id: Guid;
    name: string;
    Code: CountryCode;
    CultureInfo: string;
    CurrencyISOCode: string;
    CurrencySymbol: string;

    constructor(data: Partial<Country>) {
        Object.assign(this, data);
        this.Id = Guid.parse(String(data.Id));
    }
}

export enum CountryCode {
    NL = 'NL',
    BE = 'BE',
    DE = 'DE',
    DK = 'DK',
    FR = 'FR',
    AT = 'AT',
    IT = 'IT',
    ES = 'ES',
    GB = 'GB'
}
