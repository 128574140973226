import { Guid } from 'guid-typescript';

export class Deal {
    subscriptionId: string;
    studentSubscriptionId: string;
    fullRate: number;
    actionLabel: string;
    waitingList: number;
    useFinalRate: boolean;

    // Data from HOK
    readonly subscriptionName: string;
    readonly subscriptionCode: string;
    readonly studentSubscriptionCode: string;
    readonly discountInitialRate: number;
    readonly discountFinalRate: number;
    readonly studentDiscountInitialRate: number;
    readonly studentDiscountFinalRate: number;
    readonly oneTimeFee: number;
    readonly minimumSubscriptionDuration: number;
    readonly preOrder: boolean;
    readonly assetCategory: string;

    constructor(data: Partial<Deal>) {
        Object.assign(this, data);
    }

    getRate(student: boolean = false) {
        if (student) {
            return this.useFinalRate ? this.studentDiscountFinalRate : this.studentDiscountInitialRate;
        } else {
            return this.useFinalRate ? this.discountFinalRate : this.discountInitialRate;
        }
    }
}
