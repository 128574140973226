import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FileuploadService {
    constructor(
        private http: HttpClient
    ) { }

    uploadFile(file: any): Observable<any> {
        const url = environment.apiUrl + 'v1/File';

        const uploadData = new FormData();
        uploadData.append('myFile', file, file.name);

        return this.http.post(url, uploadData, { observe: "response", responseType: "json" });
    }
}
