import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@handlebar/angular';

@Component({
  selector: 'mc-deal-remove',
  templateUrl: './deal-remove.component.html',
  styleUrls: ['./deal-remove.component.scss']
})
export class DealRemoveComponent {

  constructor(
    private dialogRef: DialogRef,
  ) { }

  execute() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
