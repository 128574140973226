import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileuploadService } from '../../../core/http/fileupload.service';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
    @Output() outputFilename = new EventEmitter<string>();
    @Input() label: string;
    @Input() isSmall: false;
    @Input() value: string;
    fileName = '';

    constructor(
        private fileUploadService: FileuploadService
    ) {}

    ngOnInit() {
        if (this.value) {
            this.outputFilename.emit(this.value);
            this.fileName = this.value;
        }
    }

    uploadFile(event) {
        const file = event[0];

        return this.fileUploadService.uploadFile(file).subscribe((resp) => {
            this.outputFilename.emit(resp.headers.get('location'));
            return this.fileName = resp.headers.get('location');
        });
    }

    /** Temporary Remove Element */
    clearUpload() {
        this.fileName = '';
        this.outputFilename.emit('');
        this.value = '';
    }
}
