import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './pages/dashboard/dashboard.component';

const routes: Routes = [
    { path: 'landingpages', loadChildren: () => import('../landingpage/landingpage.module').then(m => m.LandingpageModule) },
    // { path: 'feature-toggle', loadChildren: () => import('../featuretoggle/featuretoggle.module').then(m => m.FeatureToggleModule) },
    { path: '', component: DashboardComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class CoreRoutingModule { }
