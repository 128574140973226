import { Injectable } from '@angular/core';
import { Landingpage } from 'src/modules/shared/models/landingpage';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LandingpageStateService {
  // Private behaviorsubject (It is read/write)
  private readonly _landingpage$ = new BehaviorSubject<Landingpage>(null);

  // Expose as observable (read only)
  readonly landingpage$ = this._landingpage$.asObservable();

  constructor() {}

  setLandingpage(landingpage: Landingpage) {
    this._landingpage$.next(landingpage);
  }

  getNew(): Observable<Landingpage> {
    this._landingpage$.next(null);
    return this.landingpage$;
  }
}
