import { Component, OnInit } from '@angular/core';
import { DealStateService } from 'src/modules/core/services/deal-state.service';
import { Deal } from 'src/modules/shared/models/deal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogRef } from '@handlebar/angular';
import { SubscriptiontypeService } from 'src/modules/core/http/subscriptiontype.service';
import { LandingpageStateService } from 'src/modules/core/services/landingpage-state.service';
import { switchMap, take } from 'rxjs/operators';
import { SubscriptionTypeListItem } from 'src/modules/shared/models/subscription-type';
import { forkJoin } from 'rxjs';
import { Guid } from 'guid-typescript';


@Component({
    selector: 'mc-deal-edit',
    templateUrl: './deal-edit.component.html',
    styleUrls: ['./deal-edit.component.scss']
})
export class DealEditComponent implements OnInit {
    deal: Deal;
    dealFormGroup: FormGroup;
    subscriptionTypes: SubscriptionTypeListItem[];
    subscriptionTypeCodes: string[] = [''];
    submitted = false;

    constructor(
        private dialogRef: DialogRef,
        private dealStateService: DealStateService,
        private subscriptionTypeService: SubscriptiontypeService,
        private landingpageStateService: LandingpageStateService,
    ) { }

    ngOnInit() {
        this.deal = this.dealStateService.getDeal();

        this.landingpageStateService.landingpage$.pipe(
            take(1),
            switchMap(landingpage => this.subscriptionTypeService.getSubscriptionTypes(landingpage.country))
        ).subscribe(subscriptionTypes => {
            // Set subscriptionType arrays
            this.subscriptionTypes = subscriptionTypes;
            this.subscriptionTypeCodes = subscriptionTypes.map(type => type.code).filter(code => code && code.length > 1);
        });

        this.dealFormGroup = new FormGroup({
            subscriptionCode: new FormControl(this.deal.subscriptionCode),
            studentSubscriptionCode: new FormControl(this.deal.studentSubscriptionCode),
            fullRate: new FormControl(this.deal.fullRate, [Validators.required]),
            actionLabel: new FormControl(this.deal.actionLabel),
            waitingList: new FormControl(this.deal.waitingList || 0),
            useFinalRate: new FormControl(this.deal.useFinalRate || false),
        });
    }

    submitForm($event: MouseEvent) {
        $event.preventDefault();
        this.submitted = true;

        const data = this.dealFormGroup.value;

        forkJoin([
            this.subscriptionTypeService.getSubscriptionType(this.getSubscriptionTypeIdByCode(data.subscriptionCode)),
            this.subscriptionTypeService.getSubscriptionType(this.getSubscriptionTypeIdByCode(data.studentSubscriptionCode))
        ]).subscribe(subscriptionTypes => {
            data.subscriptionId = subscriptionTypes[0].id;
            data.subscriptionName = subscriptionTypes[0].name;
            data.discountInitialRate = subscriptionTypes[0].initialRate;
            data.discountFinalRate = subscriptionTypes[0].finalRate;
            data.studentSubscriptionId = subscriptionTypes[1] ? subscriptionTypes[1].id : null;
            data.studentDiscountInitialRate = subscriptionTypes[1] ? subscriptionTypes[1].initialRate : null;
            data.studentDiscountFinalRate = subscriptionTypes[1] ? subscriptionTypes[1].finalRate : null;
            data.oneTimeFee = subscriptionTypes[0].oneTimeFee;
            data.assetCategory = subscriptionTypes[0].assetCategory;
            data.minimumSubscriptionDuration = subscriptionTypes[0].minimumSubscriptionDuration;
            data.preOrder = subscriptionTypes[0].preOrder;

            this.deal = new Deal(data);

            this.dialogRef.close(this.deal);
        });
    }

    private getSubscriptionTypeIdByCode(code: string): Guid {
        const st = this.subscriptionTypes.find(subscriptionType => subscriptionType.code == code);
        if (st) {
            return st.id;
        }
    }
}
