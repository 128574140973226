import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Country, CountryCode } from 'src/modules/shared/models/country';

@Injectable({
    providedIn: 'root'
})
export class CountryService {
    $countries: Observable<Country[]>;

    constructor(private http: HttpClient) { }

    getCountries(): Observable<Country[]> {
        if (!this.$countries) {
            const url = environment.baseApiUrl + 'v1/country';
            this.$countries = this.http.get<Country[]>(url).pipe(
                map((countries) => countries.map((country) => new Country(country))),
                shareReplay(1),
            );
        }

        return this.$countries;
    }

    getCountryByCode(countryCode: CountryCode): Observable<Country> {
        return this.getCountries().pipe(
            map(countries => countries.find(country => country.Code == countryCode))
        );
    }
}
