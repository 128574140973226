import { Injectable } from '@angular/core';
import { Deal } from 'src/modules/shared/models/deal';

@Injectable({
  providedIn: 'root'
})
export class DealStateService {
  private _deal: Deal;
  private _index: number;

  getDeal(): Deal {
    return this._deal;
  }

  getIndex(): number {
    return this._index;
  }

  setDealState(deal: Deal, index: number) {
    this._deal = deal;
    this._index = index;
  }

  constructor() { }
}
