import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubscriptionTypeListItem, SubscriptionType } from 'src/modules/shared/models/subscription-type';
import { CountryCode } from 'src/modules/shared/models/country';
import { CountryService } from './country.service';
import { Guid } from 'guid-typescript';

@Injectable({
    providedIn: 'root'
})
export class SubscriptiontypeService {
    constructor(
        private http: HttpClient,
        private countryService: CountryService,
    ) { }

    getSubscriptionType(id: Guid): Observable<SubscriptionType> {
        if (!id) {
            return of<SubscriptionType>(null);
        }

        const url = environment.baseApiUrl + 'v1/SubscriptionType/' + id;
        return this.http.get<SubscriptionType>(url).pipe(
            map(data => new SubscriptionType(data))
        );
    }

    getSubscriptionTypes(countryCode: CountryCode): Observable<SubscriptionTypeListItem[]> {
        return this.countryService.getCountryByCode(countryCode).pipe(
            switchMap(country => {
                const url = environment.baseApiUrl + 'v1/Country/' + country.Id + '/SubscriptionTypes';
                return this.http.get<{ items: SubscriptionTypeListItem[] }>(url).pipe(
                    map(object => object.items),
                    map(subscriptionTypes => subscriptionTypes.map((subscriptionType) => { return new SubscriptionTypeListItem(subscriptionType) })),
                );
            })
        );
    }
}
