import { Guid } from 'guid-typescript';

export class SubscriptionTypeListItem {
    id: Guid;
    name: string;
    code: string;

    constructor(data: Partial<SubscriptionTypeListItem>) {
        this.id = Guid.parse(String(data.id));
        Object.assign(this, data);
    }
}

export class SubscriptionType {
    id: Guid;
    name: string;
    code: string;
    initialRate: number;
    finalRate: number;
    oneTimeFee: number;
    preOrder: boolean;
    minimumSubscriptionDuration: number;
    assetCategory: string;

    constructor(data: Partial<SubscriptionType>) {
        this.id = Guid.parse(String(data.id));
        Object.assign(this, data);
    }
}
